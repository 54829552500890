<ion-content class="ion-padding">
    <div class="login-logo"></div>

    <form [formGroup]="loginForm" (ngSubmit)="submit()">
        <ion-item lines="none" class="ion-margin-vertical">
            <ion-label class="ion-text-wrap">
                <h1 class="header">Login</h1>
                <p>Enter the email and password for your account to start your session.</p>
            </ion-label>
        </ion-item>

        <ion-item lines="full">
            <ion-label position="stacked">Email</ion-label>
            <ion-input type="email" formControlName="email" placeholder="john.smith@example.co.uk" autocomplete="email"></ion-input>

            <ion-note slot="error" *ngIf="submitted && loginForm.controls.email.hasError('required')">Email is required.</ion-note>
            <ion-note slot="error" *ngIf="submitted && loginForm.controls.email.hasError('email')">Please enter a valid email.</ion-note>
        </ion-item>

        <ion-item lines="full">
            <ion-label position="stacked">Password</ion-label>
            <ion-input type="password" formControlName="password" placeholder="Your password" autocomplete="password"></ion-input>
            <ion-note slot="error" *ngIf="submitted && loginForm.controls.password.hasError('required')">Password is required.</ion-note>
        </ion-item>

        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button type="submit" expand="block">Login</ion-button>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col>
                    <ion-button size="small" fill="clear" [routerLink]="['/forgot-password']">Forgot password?</ion-button>
                </ion-col>
                <ion-col>
                  <ion-button style="float: right;" size="small" fill="clear" href="https://app.firesurveypro.co.uk/sign-up" target="_blank">Sign-up</ion-button>
              </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>

<ion-footer class="ion-padding">
    <ion-text>v{{ appVersion }}</ion-text>
</ion-footer>
